import './App.css';
import Dice from './components/Dice.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Dice />
    </div>
  );
}

export default App;
